import React, { useEffect, useRef, useState } from "react";
import cv from "@techstark/opencv-js";
import Webcam from "react-webcam";
import { loadHaarFaceModels, detectHaarFace } from "./haarFaceDetection";
import "./style.css";

window.cv = cv;

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user"
};

const WebcamCapture = () => {
  const grayImgRef = useRef();
  const cannyEdgeRef = useRef();
  const haarFaceImgRef = useRef();
  const [imgUrl, setImgUrl] = useState(null);
  const webcamRef = React.useRef(null);

  useEffect(() => {
    loadHaarFaceModels();
  }, []);

  useEffect(() => {
    if (imgUrl) {
    }
  }, [imgUrl]);


  const capture = React.useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      setImgUrl(imageSrc);
    },
    [webcamRef]
  );

  /////////////////////////////////////////
  //
  // process image with opencv.js
  //
  /////////////////////////////////////////
  const processImage = (imgSrc) => {
    const img = cv.imread(imgSrc);

    // to gray scale
    const imgGray = new cv.Mat();
    cv.cvtColor(img, imgGray, cv.COLOR_BGR2GRAY);
    cv.imshow(grayImgRef.current, imgGray);

    // detect edges using Canny
    const edges = new cv.Mat();
    cv.Canny(imgGray, edges, 100, 100);
    cv.imshow(cannyEdgeRef.current, edges);

    // detect faces using Haar-cascade Detection
    const haarFaces = detectHaarFace(img);
    cv.imshow(haarFaceImgRef.current, haarFaces);

    // need to release them manually
    img.delete();
    imgGray.delete();
    edges.delete();
    haarFaces.delete();
  };

  return (
    <div>
      <div style={{ marginTop: "30px" }}>
        <span style={{ marginRight: "10px" }}>Select an image file:</span>
        {!imgUrl && 
        <button onClick={capture}>Capture photo</button>
        }
        {imgUrl && 
        <button onClick={() => setImgUrl(null)}>Clear photo</button>
        }
        {!imgUrl && 
        <Webcam
          audio={false}
          height={720}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width={1280}
          videoConstraints={videoConstraints}
        />}
      </div>

      {imgUrl && (
        <div className="images-container">
          <div className="image-card">
            <div style={{ margin: "10px" }}>↓↓↓ The original image ↓↓↓</div>
            <img
              alt="Original input"
              src={imgUrl}
              onLoad={(e) => {
                processImage(e.target);
              }}
            />
          </div>

          <div className="image-card">
            <div style={{ margin: "10px" }}>↓↓↓ The gray scale image ↓↓↓</div>
            <canvas ref={grayImgRef} />
          </div>

          <div className="image-card">
            <div style={{ margin: "10px" }}>↓↓↓ Canny Edge Result ↓↓↓</div>
            <canvas ref={cannyEdgeRef} />
          </div>

          <div className="image-card">
            <div style={{ margin: "10px" }}>
              ↓↓↓ Haar-cascade Face Detection Result ↓↓↓
            </div>
            <canvas ref={haarFaceImgRef} />
          </div>
        </div>
      )}
    </div>
  );
};

export default WebcamCapture;
